import React, { FunctionComponent } from "react";
import Launch from "@material-ui/icons/Launch";
import { Chip } from "@material-ui/core";
import styled from "styled-components";

const StyledChip = styled(Chip)`
  padding-left: 12px;
  padding-right: 12px;
`;

type Property = {
  link: string;
  label: string;
}

export const LinkChip: FunctionComponent<Property> = (props) => (
  <StyledChip
    color="secondary"
    size="small"
    label={props.label}
    component="a"
    href={props.link}
    clickable
    icon={<Launch />}
  />
);