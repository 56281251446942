import React from "react";
import { graphql } from "gatsby";
import { Layout } from "./Layout";
import { HeadlineBlock } from "../components/articles/HeadlineBlock";
import { LinkChip } from "../components/common/LinkChip";
import { Chip, Box, Typography } from "@material-ui/core";
import styled from "styled-components";
import { AstRenderer } from "../components/articles/AstRenderer";
import { ShareBelt } from "../components/social/ShareBelt";
import { BreadNav } from "../components/common/BreadNav";
import { CodeSyntaxHighlight } from "../components/helmets/CodeSyntaxHightlight";
import "../styles/article.css";
import { IframelySetup } from "../components/helmets/IframelySetup";
import { Seo } from "../components/helmets/Seo";


const StyledChip = styled(Chip)`
  padding-left: 12px;
  padding-right: 12px;
`;

const StyledArticleBox = styled.article`
  max-width: 960px;
`;

const Post = ({data}) =>{
  const markdownRemark = data.markdownRemark;

  const renderLink = (link: string | null | undefined, label?: string | null | undefined) =>{
    if(link && label){
      return (
        <LinkChip link={link} label={label} />
      );
    }
    else{
      return null;
    }
  }

  return(
    <Layout>
      <Seo
        title={markdownRemark.frontmatter.title}
        article
        description={markdownRemark.frontmatter.description}
        image={markdownRemark.frontmatter.cover?.childImageSharp?.fluid.src}
      />
      <CodeSyntaxHighlight />
      <IframelySetup />
      <Box my={2}>
        <BreadNav currentPage={""} links={[{label: "Home", link: "/"}, {label: "Posts", link: "/posts"}]} />
      </Box>
      <StyledArticleBox>
        <HeadlineBlock title={markdownRemark.frontmatter.title}>
          <Box mt={1} mb={2}>
            <Typography variant="body2" component="span" color="textSecondary">
              {markdownRemark.frontmatter.date}
            </Typography>
          </Box>
          <Box my={1}>
            {renderLink(markdownRemark.frontmatter?.link, markdownRemark.frontmatter?.linklabel)}
          </Box>
          <Box my={1}>
            <StyledChip variant="outlined" size="small" label={markdownRemark?.frontmatter?.maintag!} />
          </Box>
        </HeadlineBlock>
        <AstRenderer htmlAst={markdownRemark.htmlAst} />
        <Box my={4}>
          <ShareBelt
            title={markdownRemark.frontmatter.title}
            description={markdownRemark.frontmatter.description}
            path={`/posts${markdownRemark.fields.slug}`}
          />
        </Box>
      </StyledArticleBox>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String) {
    markdownRemark(
      fileAbsolutePath: {regex: "/posts//"},
      fields: { slug: { eq: $slug } }
    ){
      frontmatter {
        cover {
          childImageSharp {
            fluid(maxWidth: 600) {
              src
            }
          }
        }
        date(formatString: "YYYY/MM/DD")
        description
        maintag
        title
        link
        linklabel
      }
      excerpt(pruneLength: 160)
      htmlAst
      fields {
        slug
      }
    }
  }
`;

export default Post;